import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import CustomHeadlineA from '../../shared/section-headlines/custom-headline-a';
import s from './text-image-flip-a.module.scss';

export default function textImageFlipA({
  title,
  subtitle,
  paragraphs,
  button,
  image,
  text_image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.contentWrapper}>
            <CustomHeadlineA className={s.customHeadline} {...{ title, subtitle }}  />
            {paragraphs.map(el => (
              <p
                className={s.text}
                dangerouslySetInnerHTML={{ __html: el.text }}
            />
            ))}
            <Button className={s.button} href={button.href}>
              {button.button_text}
            </Button>
          </div>
          <div className={s.imageWrapper}>
            <p
              className={s.imgText}
              dangerouslySetInnerHTML={{ __html: text_image }}
            />
            <Img className={s.image} src={image?.title} alt={image?.title} />
          </div>
        </div>
      </div>
    </Container>
  );
}
