import React from 'react';
import Container from 'container';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './banner_about_me.module.scss';

export default function Bannerection({ title, subtitle, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <TitleSubtitleHeadline
          titleClassName={s.title}
          subtitleClassName={s.subtitle}
          {...{ title, subtitle }}
        />
      </div>
    </Container>
  );
}
