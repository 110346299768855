import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import Icon from 'icon';
import s from './cards-with-icon.module.scss';

export default function CardsWithIcon({ cards, button_text, ...props }) {

  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        {cards.map(({ image, title, text, icon }) => (
          <div className={`${s.card} ${!image && s.noImage}`}>
            <Img className={s.image} src={image?.title} />
            <div className={`${s.subcard} ${!image && s.noImage}`}>
              <Headline className={s.title} h2 html={title} />
              <p
                className={s.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              <Icon className={s.icon} icon={icon} />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
