import React from 'react';
import Img from 'img';

import s from './blog_image.module.scss';

export default function BlogImage({ image }) {
  return (
    <div className={s.root}>
      <Img src={image?.title || 'placeholder'} className={s.img} />
    </div>
  );
}
