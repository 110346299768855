import React from 'react';
import Container from 'container';
import Icon from 'icon';
import { Link } from 'gatsby';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './socials_banner.module.scss';

export default function BannerLpSection({ title, subtitle, icons, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <TitleSubtitleHeadline
          className={s.headline}
          titleClassName={s.title}
          subtitleClassName={s.subtitle}
          {...{ title, subtitle }}
        />
        <div className={s.icons}>
          {icons.map(({ icon, href }) => (
            <React.Fragment key={icon}>
              {href.includes('http') ? (
                <a href={href} rel="noopener noreferrer" target="_blank">
                  <Icon icon={icon} />
                </a>
              ) : (
                <Link to={href}>
                  <Icon icon={icon} />
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Container>
  );
}
