import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import Countdown from 'shared/countdown';
import FormForBanner from 'shared/form-for-banner';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import s from './countdown-lp-a.module.scss';

export default function CountdownLpA({
  date,
  board_message,
  form_title,
  form_subtitle,
  background_image,
  ...props
}) {
  return (
    <Container className={s.root}>
      <div className={s.overlay} />
      <div className={s.bgImgWrapper}>
        <Img
          className={s.bgImg}
          src={background_image?.title}
          alt={background_image?.title}
        />
      </div>
      <div className={s.content}>
        <div className={s.countdownWrapper}>
          <Countdown white {...{ date }} />
        </div>
        <div className={s.bottomContent}>
          <div className={s.formWrapper}>
            <SimpleTitleSubtitle
              className={s.titleWrapper}
              titleClassName={s.titleClassName}
              subtitleClassName={s.subtitleClassName}
              {...{ title: form_title, subtitle: form_subtitle }}
            />
            <Headline className={s.arrow} wildExtra h1 html="L" />
            <div className={s.fromContainer}>
              <FormForBanner {...props} />
            </div>
          </div>
          <div className={s.boardImgWrapper}>
            <Headline tertiary center wild className={s.boardMessage}>
              {board_message}
            </Headline>
            <Img className={s.board} src="la-board" alt="la-board" />
          </div>
        </div>
      </div>
    </Container>
  );
}
