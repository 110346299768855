import React from 'react';
import Container from 'container';
import ButtonV2 from 'button-v2';
import Countdown from 'shared/countdown';
import s from './countdown-lp.module.scss';

export default function CountdownLP({
  date,
  button,
  challenge_closed,
  closed_headline,
  closed_text,
  ...props
}) {
  return (
    <Container className={s.root}>
      <Countdown
        {...{ date, challenge_closed, closed_headline, closed_text }}
      />
      <ButtonV2
        tertiary
        wrapperClass={s.buttonWrapper}
        buttonClass={s.button}
        {...{ button, ...props }}
      />
    </Container>
  );
}
