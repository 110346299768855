import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import getTag from 'utils/get-tag';
import s from './blog.module.scss';

export default function Blog({ headline, subtitle, posts, rotate_headline }) {
  const rotateClass = rotate_headline ? s.rotate : '';
  return (
    <Container className={s.root}>
      <div className={s.title_wrapper}>
        <Headline
          wild
          className={`${s.headline} ${rotateClass}`}
          dangerouslySetInnerHTML={{ __html: headline }}
        />
        <p
          className={s.subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </div>
      <div className={s.posts_wrapper}>
        {posts.map(({ image, title, button, text }, idx) => {
          const PostText = getTag(text);
          const order2Class = idx % 2 !== 0 ? s.order2 : '';
          const endOrStartClass = idx % 2 === 0 ? s.end : s.start;
          const textAlignClass = idx % 2 === 0 ? s.txt_right : s.txt_left;
          const flexAlignClass = idx % 2 === 0 ? s.end : s.start;
          return (
            <div className={s.post}>
              {image && (
              <Img
                className={`${s.image} ${order2Class}`}
                src={image?.title}
                alt={image?.title}
              />
              )}
              <div
                className={`${s.txt_wrapper} ${flexAlignClass}`}
              >
                <h2
                  className={`${endOrStartClass} ${s.post_title}`}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {text && (
                <PostText
                  className={`${s.post_text} ${textAlignClass}`}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                )}
                <Button className={s.button} href={button.href}>
                  {button.button_text}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
