import React from 'react';
import Container from 'container';
import Img from 'img';
import Icon from 'icon';
import Button from 'button';
import Headline from 'headline';
import s from './join_banner.module.scss';

export default function BannerLpSection({
  background_image,
  socials,
  title,
  text,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Img className={s.backgroundImage} src={background_image?.title} />
        <div className={s.flexed}>
          <Headline className={s.title} h2 tertiary html={title} />
        </div>
        <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={s.socials}>
          {socials.map(({ number, icon }) => (
            <div className={s.social} >
              <h1 className={s.number}>{number}</h1>
              <Icon className={s.icon} icon={icon} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
