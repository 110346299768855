import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import Icon from 'icon';
import s from './long.module.scss';

export default function HeroSection({
  headline,
  long_cards,
  subtitle,
  text,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Headline h1 wild secondary html={headline} center />
        <Headline h3 center className={s.subtitle} html={subtitle} />
        <Headline h3 className={s.text}>
          <span>
          {text}
          </span>
          <div className={`${s.stars} ${s.showDesktop}`}>
            {new Array(Math.floor(parseInt(5, 10))) // change the 5 to avg rate if needed
              .fill('peace, bro')
              .map(() => (
                <Icon className={s.star} icon="star" />
              ))}
          </div>
        </Headline>
        <div className={`${s.stars} ${s.hideDesktop}`}>
          {new Array(Math.floor(parseInt(5, 10))) // change the 5 to avg rate if needed
            .fill('peace, bro')
            .map(() => (
              <Icon className={s.star} icon="star" />
            ))}
        </div>
        <div className={s.cards}>
          {long_cards.map(({ autor, date, image, text, title }, i) => (
            <div className={`${s.card} ${i % 2 !== 0 ? s.flip : ''}`}>
              <Img className={s.img} src={image?.title} alt={image?.title} />
              <div className={s.textWrapper}>
                <Headline
                  h2
                  className={`${s.cardTitle} ${i % 2 !== 0 ? s.fliptitle : ''}`}
                  html={title}
                />
                <div className={`${s.stars} ${i % 2 !== 0 ? s.alignRight : ''}`}>
                  {new Array(Math.floor(parseInt(5, 10))) // change the 5 to avg rate if needed
                    .fill('peace, bro')
                    .map(() => (
                      <Icon className={s.star} icon="star" />
                    ))}
                </div>
                <p className={s.cardText}>{text}</p>
                <div
                  className={`${s.authorWrapper} ${
                    i % 2 !== 0 ? s.alignRight : ''
                  }`}
                >
                  <p>
                    <span className={s.author}>{autor}</span>
                    {' - '}
                    <span className={s.date}>{date}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
