import React, { useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import Icon from 'icon';
import s from './product_lp.module.scss';

export default function ProductLpSection({  title,
  subtitle,
  price,
  price_reduction,
  reviews,
  paragraphs,
  images,
  ...props}) {
  const [clickedImage, setClickedImage] = useState(0);
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.imagesWrapper}>
            { images && images.map(({ img }, i) => (
              <div className={s.image} onClick={() => setClickedImage(i)}>
                <Img className={s.simg} src={img.title} key={i} />
              </div>
            ))}
          </div>
          <div className={s.largerImageWrapper}>
            {images && <Img className={s.limg} src={images[clickedImage].img.title} />}
          </div>
        </div>
        <div className={s.productInfo}>
          <Headline className={s.title} h2 tertiary html={title} />
          <p
            className={s.subtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <div className={s.flexed}>
            <p
              className={s.price}
              dangerouslySetInnerHTML={{ __html: price }}
            />
            <div className={s.redLine} />
            <p
              className={s.priceReduction}
              dangerouslySetInnerHTML={{ __html: price_reduction }}
            />
          </div>
          <div className={s.stars}>
            <Icon className={s.star} icon="star" />
            <Icon className={s.star} icon="star" />
            <Icon className={s.star} icon="star" />
            <Icon className={s.star} icon="star" />
            <Icon className={s.star} icon="star" />
            <p
              className={s.reviews}
              dangerouslySetInnerHTML={{ __html: reviews }}
            />
          </div>
          <ul>
            {paragraphs.map(({ text }, i) => (
              <li>
                <p
                  className={s.listItem}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
}
