import React, { useState, useRef, useEffect, createRef } from 'react';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Icon from 'icon';
import Container from 'container';
import Button from 'button';
import TitleSubtitleHeadline from 'headlines/simple-title-subtitle';

import s from './bookshelf.module.scss';

export default function Bookshelf({
  title,
  subtitle,
  books,
  article_title,
  article_text,
  image,
  ...props
}) {
  const [active, setActive] = useState({ ...books[0], active: 0 });
  const [transition, setTransition] = useState(false);
  const ArticleTextTag = getTag(active.article_text);
  const bookClasses = [s.one, s.two, s.three, s.four, s.five, s.six, s.seven];

  const handleClick = i => {
    setActive({ ...active, active: i });
    setTransition(true);
    setTimeout(() => {
      setTransition(false);
      setActive({ ...books[i], active: i });
    }, 200);
  };

  const handlePrev = () => {
    const nowActive = active.active;
    setActive({
      ...books[nowActive === 0 ? nowActive : nowActive - 1],
      active: nowActive === 0 ? nowActive : nowActive - 1
    });
  };
  const handleNext = () => {
    const nowActive = active.active;
    setActive({
      ...books[nowActive === books.length - 1 ? nowActive : nowActive + 1],
      active: nowActive === books.length - 1 ? nowActive : nowActive + 1
    });
  };

  return (
    <Container className={s.root}>
      <TitleSubtitleHeadline
        invert
        className={s.headline}
        titleClassName={s.titleClassName}
        {...{ title, subtitle }}
      />
      <ul className={s.books}>
        <div className="" />
        <div className={s.plantWrapper}>
          <Img className={s.plant} src="plant" alt="plant" />
        </div>
        <div className={s.round} />
        {books.map((book, i) => (
          <li
            key={book.book_title}
            className={`${s.book} ${bookClasses[i]} ${
              active.active === i ? s.active : ''
            }`}
            onClick={e => {
              handleClick(i);
            }}
          >
            <span className={s.bookLink} href={book.book_href}>
              {book.book_title}
            </span>
          </li>
        ))}
      </ul>
      <article className={`${s.article}`}>
        <div
          className={`${s.arrow} ${s.arrowLeft} ${
            active.active === 0 ? s.disabled : ''
          }`}
          onClick={() => handlePrev()}
        >
          <Icon icon="slider_arrow" />
        </div>
        <div
          className={`${s.arrow} ${s.arrowRight} ${
            active.active === books.length - 1 ? s.disabled : ''
          }`}
          onClick={() => handleNext()}
        >
          <Icon icon="slider_arrow" />
        </div>
        <div className={`${s.overlay} ${transition ? s.transition : ''}`} />
        <div>
          <Headline h3 className={s.articleTitle} html={active.article_title} />
          <ArticleTextTag
            className={`italic ${s.articleText}`}
            dangerouslySetInnerHTML={{ __html: active.article_text }}
          />
        </div>
        <div className={s.buttonAndImgWrapper}>
          <div className={s.articleImgWrapper}>
            <Img
              className={s.articleImg}
              src={active.article_image?.title}
              alt={active.article_image?.title}
            />
          </div>
          <Button className={s.button} tertiary href={active.book_href} native>
            {active.button_text}
          </Button>
        </div>
      </article>
    </Container>
  );
}
