import React, { useState, useEffect } from 'react';
import Container from 'container';
import Icon from 'icon';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper';
import 'swiper/css/swiper.min.css';
import { Navigation } from 'swiper/js/swiper.esm';
import VideoFrame from 'shared/video-frame';
import TitleSubtitle from 'shared/section-headlines/simple-title-subtitle';

import s from './slider.module.scss';

export default function Slider({ slide, title, subtitle, ...props }) {
  const [swiper, updateSwiper] = useState(null);
  const [playing, setPLaying] = useState(false);
  const [waypointed, setWaypointed] = useState(false);
  const enableSliding = slide.length > 1;

  function pauseAll() {
    Array.from(document.querySelectorAll('.video-hero')).forEach(stream => stream.pause());
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
      pauseAll();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
      pauseAll();
    }
  };

  const params = {
    modules: [Navigation],
    spaceBetween: 30,
    centeredSlides: true,
    getSwiper: updateSwiper,
    allowSlidePrev: enableSliding,
    allowSlideNext: enableSliding
  };

  const handleClick = (tag, idx) => {
    if (!playing) {
      document.querySelector(`#video-${tag}-${idx}`).play();
      setPLaying(true);
    } else {
      setPLaying(false);
      document.querySelector(`#video-${tag}-${idx}`).pause();
    }
  };

  useEffect(() => {
    if (!waypointed) {
      setWaypointed(true);
      const script = document.createElement('script');
      script.src = 'https://embed.videodelivery.net/embed/r4xu.fla9.latest.js';
      script.setAttribute('data-cfasync', false);
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', () => pauseAll());
    }
  }, [swiper]);

  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <TitleSubtitle
          title={title}
          subtitle={subtitle}
          className={s.titleSubtitle}
        />
        <Swiper {...params}>
          {slide.map(({ video, thumbnail }, idx) => (
            <div key={idx} className={s.slide}>
              <div className={`${s.imgWrapper}`}>
                <VideoFrame {...{ video, width: '100%', height: '100%', thumbnail, idx, tag: 'hero' }} />
              </div>
              <div role="button" onClick={() => handleClick('hero', idx)} />
            </div>
          ))}
        </Swiper>
        {enableSliding && (
        <>
          <Icon
            onClick={goPrev}
            icon="slider_arrow"
            className={`${s.icon} ${s.icnLeft}`}
          />
          <Icon
            onClick={goNext}
            icon="slider_arrow"
            className={`${s.icon} ${s.icnright}`}
          />
        </>
        )}
      </div>
    </Container>
  );
}
