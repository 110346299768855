import React from 'react';
import Container from 'container';
import Button from 'button';
import Headline from 'headline';
import Img from 'img';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './text_image_about_me_b.module.scss';

export default function textImageFlipA({
  title,
  wild_extra,
  subtitle,
  inner_title,
  paragraphs,
  image,
  bold_text,
  background_image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.contentWrapper}>
            <div className={s.overlay} />
            <div className={s.backgroundImageWrapper}>
              <Img className={s.backgroundImage} src={background_image?.title} />
            </div>
            <TitleSubtitleHeadline
              className={s.headline}
              titleClassName={s.title}
              subtitleClassName={s.subtitle}
              {...{ title, wild_extra, subtitle }}
            />
            <div className={s.flex}>
              <div className={s.imageWrapper}>
                <div className={s.backSquare} />
                <Img className={s.image} src={image?.title} alt={image?.title} />
              </div>
              <div className={s.textWrapper}>
                <Headline className={s.innerTitle} html={inner_title} />
                {paragraphs.map((el, i) => (
                  <p
                    key={`${el.text.slice(0, 1)}-${i}`}
                    className={`italic ${s.text}`}
                    dangerouslySetInnerHTML={{ __html: el.text }}
                  />
                ))}
                <p
                  className={`italic ${s.boldText}`}
                  dangerouslySetInnerHTML={{ __html: bold_text }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
