import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './hero.module.scss';

export default function HeroSection({ image, title, subtitle1, subtitle2, button, ...props }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Img className={s.bgimage} src={image?.title || 'placeholder'} alt={image?.title || 'placeholder'} />
        <div className={s.content}>
          <Headline className={s.title} h1 wild secondary html={title} />
          <Headline className={s.subtitle} h1 html={subtitle1} />
          <Headline className={s.subtitle} h1 html={subtitle2} />
          <Button className={s.button} tertiary href={button.href}>
            {button.text}
          </Button>
        </div>
      </div>
    </Container>
  );
}
