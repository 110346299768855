import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import Icon from 'icon';
import s from './cards-lp.module.scss';

export default function CardsWithIcon({ cards, headline, subtitle }) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.headlineWrapper}>
          <Headline h1 wild secondary html={headline} center />
          <Headline h3 center className={s.subtitle} html={subtitle} />
        </div>
        <div className={s.cards}>
          {cards.map(({ image, paragraphs, title }) => (
            <div className={s.card}>
              <Img className={s.image} src={image?.title} />
              <div className={s.subcard}>
                <Headline className={s.title} h2 html={title} />
                {paragraphs.map(({ text }) => (
                  <p
                    className={s.text}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
