import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import getTag from 'utils/get-tag';
import s from './cards.module.scss';

export default function TwocardsPerRow({ title, subtitle, cards, ...props }) {
  return (
    <Container white className={s.root}>
      <div className={s.wrapper}>
        <Headline secondary wild className={s.title} html={title} />
        <Headline h3 className={s.subtitle} html={title} />
        <div className={s.cards}>
          {cards.map(({ text, title, image, button, subtitle }) => {
            const SubtitleTag = getTag(subtitle);
            const TextTag = getTag(subtitle);
            return (
              <div className={s.card}>
                <div className={s.imgWrapper}>
                  <Img className={s.img} src={image?.title} alt={image?.title} />
                </div>
                <div className={s.cardContent}>
                  <div>
                    <Headline h3 className={s.cardTitle} html={title} />
                    <SubtitleTag
                      className={s.cardSubtitle}
                      dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                    <TextTag className={s.cardText} dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                  <Button className={s.button} secondary href={button.href}>{button.text}</Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
