import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './text-image-flip-c.module.scss';

export default function TextImageFlipC({ image, paragraphs, button }) {
    return (
        <Container className={s.section}>
					<div className={s.wrapper}>
						<Img className={s.image} src={image?.title} />
            <div className={s.content}>
              {paragraphs.map(el => (
                <p
                  className={s.text}
                  dangerouslySetInnerHTML={{ __html: el.text }}
              />
              ))}
              <Button className={s.button} href={button.href}>
                {button.button_text}
              </Button>
            </div>
					</div>
        </Container>
    )
}