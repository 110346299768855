import React, { useState, useEffect } from 'react';
import Headline from 'headline';
import s from './countdown.module.scss';

export default function Countdown({
  date,
  white,
  challenge_closed,
  closed_headline,
  closed_text,
  ...props
}) {
  const [timeSpan, setTimeSpan] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DIC'
  ];
  const day = Number.parseInt(date.split('/')[1]);
  const month = Number.parseInt(date.split('/')[0]);
  const year = Number.parseInt(date.split('/')[2]);
  const timeout = new Date() > new Date(year, month - 1, day);

  useEffect(() => {
    const countdown = require('utils/countdown.js');
    const countdownInterval = setInterval(() => {
      setTimeSpan(countdown(new Date(), new Date(year, month - 1, day)));
      return () => clearInterval(countdownInterval);
    }, 1000);
    if (challenge_closed) clearInterval(countdownInterval);
  }, []);

  function pad2(number) {
    return `${number < 10 ? '0' : ''}${number}`;
  }
  return (
    <div className={`${s.root} ${challenge_closed ? s.challengeClosed : ''}`}>
      <div className={`${s.titleWrapper}`}>
        <span
          className={`${s.line} ${white ? s.white : ''} ${
            challenge_closed ? s.smallLine : ''
          }`}
        />
        <Headline subtitle className={`${s.title} ${white ? s.white : ''}`}>
          {challenge_closed ? (
            closed_headline
          ) : (
            <React.Fragment>
              WE START {months[month - 1]} {day}
            </React.Fragment>
          )}
        </Headline>
        <span
          className={`${s.line} ${white ? s.white : ''} ${
            challenge_closed ? s.smallLine : ''
          }`}
        />
      </div>
      <div className={s.countdown}>
        {challenge_closed ? (
          <p
            dangerouslySetInnerHTML={{ __html: closed_text }}
            className={s.closedText}
          />
        ) : (
          <React.Fragment>
            <div className={`${s.time} ${white ? s.white : ''}`}>
              <Headline className={`${s.number} ${white ? s.white : ''}`}>
                {timeout ? '00' : pad2(timeSpan.days)}
              </Headline>
              <p className={`${s.field} ${white ? s.white : ''}`}>Days</p>
            </div>
            <div className={`${s.time} ${white ? s.white : ''}`}>
              <Headline className={`${s.number} ${white ? s.white : ''}`}>
                {timeout ? '00' : pad2(timeSpan.hours)}
              </Headline>
              <p className={`${s.field} ${white ? s.white : ''}`}>Hours</p>
            </div>
            <div className={`${s.time} ${white ? s.white : ''}`}>
              <Headline className={`${s.number} ${white ? s.white : ''}`}>
                {timeout ? '00' : pad2(timeSpan.minutes)}
              </Headline>
              <p className={`${s.field} ${white ? s.white : ''}`}>Minutes</p>
            </div>
            <div className={`${s.time} ${white ? s.white : ''}`}>
              <Headline className={`${s.number} ${white ? s.white : ''}`}>
                {timeout ? '00' : pad2(timeSpan.seconds)}
              </Headline>
              <p className={`${s.field} ${white ? s.white : ''}`}>Seconds</p>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
