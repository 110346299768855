import React from 'react';
import { Helmet } from 'react-helmet';

export default function Head({
  title,
  description,
  canonical,
  ogtitle,
  ogdescription,
  ogurl,
  ogimage,
  ...props
}) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {ogtitle && <meta property="og:title" content={ogtitle} />}
      {ogdescription && (
        <meta property="og:description" content={ogdescription} />
      )}
      {ogurl && <meta property="og:url" content={ogurl} />}
      {ogimage !== undefined && ogimage && (
        <meta property="og:image" content={ogimage.url} />
      )}
      {ogimage !== undefined && ogimage && (
        <meta property="og:image:secure_url" content={ogimage.url} />
      )}
      {ogimage !== undefined && ogimage && (
        <meta property="og:image:type" content={ogimage.myme_type} />
      )}
      {ogimage !== undefined && ogimage && (
        <meta
          property="og:image:width"
          content={ogimage.sizes['large-width']}
        />
      )}
      {ogimage !== undefined && ogimage && (
        <meta
          property="og:image:height"
          content={ogimage.sizes['large-height']}
        />
      )}
      {ogimage !== undefined && ogimage && (
        <meta property="og:image:alt" content={ogimage?.title} />
      )}
      <meta property="og:type" content="website" />
    </Helmet>
  );
}
