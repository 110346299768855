import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import s from './general-hero-c.module.scss';

export default function GeneralHeroC({ image, title, wild_title, text, ...props }) {
  const TextTag = getTag(text);
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image?.title} alt={image?.title} />
        </div>
        <div className={s.title}>
          {title.map(({ text_line }) => (
            <Headline h2 className={s.titleLine} html={text_line} />
          ))}
        </div>
        <div className={s.wildTitle}>
          <Headline className={s.wildTitleLine} secondary wild html={wild_title.text_line1} />
          <Headline className={`${s.wildTitleLine} ${s.wild2}`} secondary wild html={wild_title.text_line2} />
        </div>
        <TextTag className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </Container>
  );
}
