import React, { useState, useEffect, useContext } from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import VideoFrame from 'shared/video-frame';
import s from './hero_lp.module.scss';

export default function HeroLpSection({ headline1, headline2, title, image, video, video_thumbnail, ...props }) {

  const [playing, setPLaying] = useState(false);
  const [waypointed, setWaypointed] = useState(false);


  const handleClick = () => {
    if (!playing) {
      document.querySelector('#video-hero').play();
      setPLaying(true);
    } else {
      setPLaying(false);
      document.querySelector('#video-hero').pause();
    }
  };

  useEffect(
    () => {
      if (!waypointed) {
        setWaypointed(true);
        const script = document.createElement('script');
        script.src = 'https://embed.videodelivery.net/embed/r4xu.fla9.latest.js';
        script.setAttribute('data-cfasync', false);
        script.type = 'text/javascript';
        document.head.appendChild(script);
      }
    }, []
  );
  return (


    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.flexed}>
            <Headline className={s.title} h2 html={title} />
            <div className={s.line} />
            <div className={s.col}>
              <Headline className={s.headline1} h1 wild tertiary html={headline1} />
              <Headline className={s.headline2} h1 wild tertiary html={headline2} />
            </div>
          </div>
          <div className={s.videoWrapper}>
            <VideoFrame idx="hero" video={video} width="100%" poster={video_thumbnail} />
          </div>
          <div onClick={() => handleClick()}>

          </div>
        </div>
      </div>
    </Container>
  );
}