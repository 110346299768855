import React from 'react';
import Img from 'img';
import Container from 'container';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import DoubleLineHeadline from 'headlines/double-line';
import s from './general-hero-d.module.scss';

export default function GeneralHeroD({ headline, subtitle, image, ...props }) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <DoubleLineHeadline
          className={s.headline}
          subtitleClassName={s.subtitleClassName}
          titleClassName={s.titleClassName}
          {...{ headline, subtitle }}
        />
        <div className={s.imgWrapper}>
          <Img className={s.img} src={image?.title} alt={image?.title} />
        </div>
      </div>
    </Container>
  );
}
