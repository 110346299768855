import React from 'react';
import Container from 'container';
import Button from 'button';
import Headline from 'headline';
import Img from 'img';
import CustomHeadlineA from '../../shared/section-headlines/custom-headline-a';
import s from './text_image_about_me.module.scss';

export default function textImageFlipA({
  title,
  wild_extra,
  subtitle,
  paragraphs,
  image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.contentWrapper}>
            <div className={s.imageWrapper}>
              <Img className={s.image} src={image?.title} alt={image?.title} />
            </div>
            <div className={s.flex}>
              <CustomHeadlineA
                className={s.customHeadline}
                titleClassName={s.title}
                subtitleClassName={s.subtitle}
                {...{ title, wild_extra, subtitle }}
              />
              <div className={s.textWrapper}>
                {paragraphs.map((el, i) => (
                  <p
                    key={`${el.text.slice(0, 1)}-${i}`}
                    className={`italic ${s.text}`}
                    dangerouslySetInnerHTML={{ __html: el.text }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
