import React, { useState } from 'react';
import Img from 'img';
import Icon from 'icon';

import s from './video.module.scss';

export default function VideoFrameComponent({
  video,
  width,
  height,
  tag,
  thumbnail,
  idx
}) {
  const [hideThumb, setHideThumb] = useState(false);
  const handleClick = (tag, idx) => {
    setHideThumb(true);
    document.querySelector(`#video-${tag}-${idx}`).play();
  };
  return (
    <div className={`${s.videoWrapper}`}>
      <Icon
        icon="play"
        className={s.play}
        style={{ display: hideThumb ? 'none' : 'block' }}
        onClick={() => handleClick(tag, idx)}
      />
      <div
        className={s.videoFrame}
        style={{ width, height }}
        dangerouslySetInnerHTML={{
          __html: `<stream data-cfasync="false" defer type="text/javascript" src="${video}" class="video-${tag}" controls id="video-${tag}-${idx}"></stream>`
        }}
      />
      <div
        className={s.imageWrapper}
        onClick={() => handleClick()}
        style={{ display: hideThumb ? 'none' : 'block' }}
      >
        <Img className={s.image} src={thumbnail.title} />
      </div>
    </div>
  );
}
