import React from 'react';
import Headline from 'headline';
import s from './custom-headline-a.module.scss';

export default function CustomHeadlineA({
  title,
  wild_extra,
  subtitle,
  className,
  extraClassName,
  titleClassName,
  subtitleClassName,
  invert,
  ...props
}) {
  return (
    <div className={`${s.root} ${invert ? s.invert : ''} ${className || ''}`}>
      <Headline
        h1
        wild
        secondary
        className={`${s.title} ${titleClassName}`}
        html={title}
      />
      {wild_extra ? (
        <Headline
          h1
          wildExtra
          tertiary
          className={`${s.extra} ${extraClassName}`}
          html={wild_extra}
        />
      ) : null}
      <div className={s.subtitleWrapper}>
        <Headline
          h2
          className={`${s.subtitle} ${subtitleClassName}`}
          html={subtitle}
        />
      </div>
    </div>
  );
}
