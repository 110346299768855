import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import SimpleTitleSubtitle from 'shared/section-headlines/simple-title-subtitle';
import s from './cards.module.scss';

export default function TwoCardsPerRow({ headline, subtitle, cards, outter_button, ...props }) {
  return (
    <Container white className={s.section}>
      <div className={s.wrapper}>
        <Headline h1 secondary wild html={headline} />
        <Headline h3 html={subtitle} className={s.subtitle} />
        <div className={s.allCards}>
          {cards.map(({ image, title, text, button }) => (
            <Container white className={s.cardContainer} key={title}>
              <Img src={image?.title} alt={image?.title} />
              <Container grey className={s.textContainer}>
                <Headline h3 className={s.cardTitle} html={title} />
                <p dangerouslySetInnerHTML={{ __html: text }} />
              </Container>
              <Button className={s.cardButton}>
                {button.button_text}
              </Button>
            </Container>
          ))}
        </div>
        <div className={s.buttonWrapper}>
        <Button className={s.outterButton} tertiary outlined href={outter_button.href}>
          {outter_button.button_text}
        </Button>
        </div>
      </div>
    </Container>
  );
}
