import React from 'react';
import Container from 'container';
import Button from 'button';
import Headline from 'headline';
import Img from 'img';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './bio.module.scss';

export default function Bio({
  title,
  subtitle,
  paragraphs,
  image,
  image_text,
  button,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.textWrapper}>
          <TitleSubtitleHeadline
            className={s.headline}
            titleClassName={s.title}
            subtitleClassName={s.subtitle}
            {...{ title, subtitle }}
          />
          {paragraphs.map((el, i) => (
            <p
              key={`${el.text.slice(0, 1)}-${i}`}
              className={`italic ${s.text}`}
              dangerouslySetInnerHTML={{ __html: el.text }}
            />
          ))}
        </div>
        <div calssName={s.card} >
          <div className={s.imageWrapper}>
            <Img className={s.image} src={image?.title} />
          </div>
          <div className={s.imageText}>
            <p
              className={`italic ${s.text}`}
              dangerouslySetInnerHTML={{ __html: image_text }}
            />
            <Button className={s.button} href={button.href}>
              {button.text}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
