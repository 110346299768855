import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import Icon from 'icon';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './fun_facts.module.scss';

export default function HeroSection({
  title,
  subtitle,
  list,
  background_image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Img className={s.bgImage} src={background_image?.title || 'placeholder'} />
        <TitleSubtitleHeadline
          className={s.headline}
          titleClassName={s.title}
          subtitleClassName={s.subtitle}
          {...{ title, subtitle }}
        />
        <div className={s.content}>
          {list.map((el, i) => (
            <div className={s.postWrap}>
              <Img className={s.thumbnail} src={el.image?.title} />
              <p
                key={`${el.text.slice(0, 1)}-${i}`}
                className={`italic ${s.text}`}
                dangerouslySetInnerHTML={{ __html: el.text }}
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
