import React from 'react';
import Container from 'container';
import Button from 'button';
import Img from 'img';
import TitleSubtitleHeadline from '../../shared/section-headlines/simple-title-subtitle';
import s from './text_image_lp.module.scss';

export default function textImageFlipA({
  title,
  subtitle,
  paragraphs,
  flip,
  image,
  ...props
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <TitleSubtitleHeadline
            titleClassName={s.title}
            subtitleClassName={s.subtitle}
            {...{ title, subtitle }}
          />
          <div className={s.flex}>
            <div className={s.imageWrapper}>
              <Img className={s.image} src={image?.title} alt={image?.title} />
            </div>
            <div className={s.textWrapper}>
              {paragraphs.map(({ text }) => (
                <p
                  className={s.text}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
