import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import { Link } from "gatsby";
import s from './round_pictures.module.scss';

export default function RoundPictures({ headline, pictures }) {
    return (
				<Container className={s.section}>
					<div className={s.wrapper}>
						<Headline className={s.headline} h2 html={headline} />
						<div className={s.content}>
							{pictures.map(el => (
								<div className={s.subcontent}>
									<Link className={s.link} to={el.images.href}>
									<Img className={s.image} src={el.images.image?.title}  />
									</Link>
									<Link className={s.link} to={el.images.href}>
									<Headline className={s.title} h4 dangerouslySetInnerHTML={{ __html: el.images.title }}/>
									</Link>
								</div>
							))}
						</div>
					</div>
				</Container>
    )
}