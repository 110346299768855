import React, { useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import Icon from 'icon';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper';
import 'swiper/css/swiper.min.css';
import { Navigation } from 'swiper/js/swiper.esm';
import s from './post-slides.module.scss';

export default function PostSlides({ slides, white, ...props }) {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    modules: [Navigation],
    spaceBetween: 30,
    centeredSlides: true,
    getSwiper: updateSwiper,
  };

  return (
    <Container className={`${white && s.white} ${s.section}`}>
      <div className={s.wrapper}>
        <Swiper {...params}>
          {slides.map(el => (
            <div className={s.slide}>
              <div className={s.imgContainer}>
                <Img className={s.image} src={el.image?.title} />
                {el.icon && <Icon icon={el.icon} />}
              </div>
              <div className={s.content}>
                <Headline className={s.title} h2 html={el.title} />
                <p className={s.text} dangerouslySetInnerHTML={{ __html: el.text }} />
                <p className={s.autor} dangerouslySetInnerHTML={{ __html: el.autor }} />
              </div>
            </div>
          ))}
        </Swiper>
      </div>
      <Icon onClick={goPrev} icon="slider_arrow" className={`${s.icon} ${s.icnLeft}`} />
      <Icon onClick={goNext} icon="slider_arrow" className={`${s.icon} ${s.icnright}`} />
    </Container>
  );
}
